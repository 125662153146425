import React from "react";
import { Suspense, lazy } from "react";
import { Button, Col, Row } from "antd";
// import Header from '../header/header';
import Header from "../header/index.js";
import "./style.css";
import greenTick from "../asset/home/greenTick.svg";
import Banner1 from "../asset/home/home.gif";
import tickIconRed from "../asset/home/tickIconRed.png";
import arrowIcon from "../asset/home/arrowIcon.png";
import background2 from "../asset/home/background2.png";
import Footer from "../footer/footer";
import { useHistory } from "react-router-dom";

const HomeComp2 = lazy(() => import("./comp2"));
const HomeComp3 = lazy(() => import("./comp3"));
const HomeComp4 = lazy(() => import("./comp4"));
const HomeComp5 = lazy(() => import("./comp5"));
const HomeComp6 = lazy(() => import("./comp6"));
const ContactUs = lazy(() => import("./contactus"));

function Home() {
  var ls = localStorage;
  const history = useHistory();
  return (
    <>
      <Header />
      <Row className="container_img ">
        <Col xs={24} className="container">
          <Row>
            <Col xs={24} sm={12}>
              <Col xs={24}>
                <h1 className="heading">
                  Its free to make business on{" "}
                  <span className="subText">Sendbiz</span>
                </h1>
                <p className="para1">A worldwide business directory</p>
              </Col>
              <Col xs={24} className="flex">
                <span className="tick">
                  <img src={greenTick} />
                </span>
                <p className="marginL2 para2">
                  Maximise your chances of buyers finding you.
                </p>
              </Col>
              <Col xs={24} className="flex">
                <span className="tick">
                  <img src={greenTick} />
                </span>
                <p className="marginL2 para2">
                  Get in touch with potential customers without paying in
                  commission or ads.
                </p>
              </Col>
              <Col xs={24} className="flex">
                <span className="tick">
                  <img src={greenTick} />
                </span>
                <p className="marginL2 para2">
                  Establish a relationship of trust with your potential
                  customers.
                </p>
              </Col>
              <Col xs={24} className="btn-col">
                <Button
                  className="btn-start"
                  onClick={() => {
                    history.push("/signup");
                  }}
                >
                  Start for Free
                </Button>
              </Col>
            </Col>
            <Col xs={0} sm={1} />
            <Col xs={24} sm={11}>
              <div className="imageContainerHome">
                <img
                  src={Banner1}
                  className="image2"
                  style={{ width: "700px" }}
                />
                {/* <div className="tickIconRed">
                  <img src={tickIconRed} />
                </div>
                <div className="arrow">
                  <img src={arrowIcon} />
                </div> */}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs={24} className="container_block">
          <div className="img_div">
            <img src={background2} className="image1" />
          </div>
          <h1 className="heading4">Search made easy.</h1>
          <h3 className="heading3">Find your perfect fit, fast</h3>
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <Suspense fallback={<div>Loading...</div>}>
            <HomeComp2 />
          </Suspense>
        </Col>
        <Col xs={24}>
          <Suspense fallback={<div>Loading...</div>}>
            <HomeComp3 />
          </Suspense>
        </Col>
        <Col xs={24}>
          <Suspense fallback={<div>Loading...</div>}>
            <HomeComp4 />
          </Suspense>
        </Col>
        <Col xs={24}>
          <Suspense fallback={<div>Loading...</div>}>
            <HomeComp5 />
          </Suspense>
        </Col>
        <Col xs={24}>
          <Suspense fallback={<div>Loading...</div>}>
            <HomeComp6 />
          </Suspense>
        </Col>
      </Row>
      <Row className="aboutContainner">
        <Col xs={24}>
          <Suspense fallback={<div>Loading...</div>}>
            <ContactUs />
          </Suspense>
        </Col>
      </Row>
      <Footer />
    </>
  );
}
export default Home;
